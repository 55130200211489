<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "xueDao",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#游戏的个性设置-战斗-高级-非战斗状态可招架-取消勾选(图2)',
                        ],
                        img:[
                            "com/init.png",
                            "com/feizhandou.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到隐士设置页",
                            "#按图1说明参数:",
                            "1.必填项-切换输出套路:输出套路是指你的角色主套路,用来打怪的,根据快捷栏设置切换快捷键(9小代表小键盘的9按键,其他带小的是同理)",
                            "2.必填项-切换隐士套路:血刀堂无需设置",
                            "3.必填项-技能:上面切换套路后,技能对应的快捷键,根据快捷栏设置",
                            "4.注意:输出套路建议用最高输出技能,不要用带位移的技能,比如神箭冲锋,神箭大招,阎王冲锋",
                        ],
                        img:[
                            "com/yinshi.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到血刀堂页",
                            "#按图1说明参数:",
                            "1.必填项-勾选要做的任务,如果已完成的任务需要取消勾选,已接取的任务需要放弃,让助手自动接取",
                            "2.由于血刀堂的复活点在恶人谷,如果需要连续任务执行血刀堂日常,可以勾选执行任务前T点回城到血刀堂" +
                            ",已在血刀堂就不需要勾选,直接开始任务即可",
                            "3.如果血刀浴血打怪老捡不到心脉之血,建议第一个技能放冲锋",
                            "4.接取任务时候,如果NPC的第一个任务不是要接取的任务,需要先手动接取,然后重新开始助手",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#可以把马哨,鹿,娇子,御风放到快捷栏上,移动时候自动使用",
                            "#角色移动到五仙教场景",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "com/yinshi1.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
